import React from 'react'
import styled from 'styled-components'
import { withAssetPrefix } from 'gatsby'
import { Regular, MqTablet, MqDesktop } from 'streamr-ui/css/consts'
import downloadPdf from './assets/download_pdf.png'
import downloadPdf2x from './assets/download_pdf@2x.png'
import Container from '~shared/Container'
import Br from '~shared/Br'
import { PrimaryButton, ButtonSize } from 'streamr-ui'

const Content = styled.div`
    h2 {
        margin: 0;
        font-size: 2rem;
        font-weight: ${Regular};
        line-height: 2.5rem;
        margin-bottom: 1.5rem;
    }

    p {
        margin: 0;
        margin-bottom: 3.5rem;
        line-height: 1.75rem;
    }

    @media ${MqDesktop} {
        p {
            line-height: 1.875rem;
        }
    }
`

const Icon = styled.div`
    img {
        width: 102px;
    }

    @media ${MqTablet} {
        img {
            width: 148px;
        }
    }

    @media ${MqDesktop} {
        img {
            width: 170px;
        }
    }
`

const PaddedBox = styled.div`
    background: #fafafa;
    border-radius: 8px;
    padding: 5.375rem 1.5rem 3rem;
    display: flex;
    flex-direction: column-reverse;

    ${Content} + ${Icon} {
        margin-bottom: 3.125rem;
    }

    @media ${MqTablet} {
        padding: 5.25rem 5.5rem 5.25rem 3rem;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        ${Content} + ${Icon} {
            margin-bottom: 0;
        }
    }

    @media ${MqDesktop} {
        padding: 8.5rem 13rem 8.5rem 7.5rem;
    }
`

const UnstyledMediaKit = (props) => (
    <Container {...props}>
        <PaddedBox $appear>
            <Content>
                <h2>Media Kit</h2>
                <p>
                    Download all brand assets in
                    <Br tablet />
                    one file,
                    <Br desktop />
                    includes logos, images
                    <Br tablet />
                    and token symbols.
                </p>
                <PrimaryButton
                    tag="a"
                    size={ButtonSize.Moose}
                    href={withAssetPrefix('/resources/streamr_brand_assets.zip')}
                >
                    Download Media Kit
                </PrimaryButton>
            </Content>
            <Icon>
                <img src={downloadPdf} srcSet={downloadPdf2x} alt="Download Media Kit" />
            </Icon>
        </PaddedBox>
    </Container>
)

const MediaKit = styled(UnstyledMediaKit)`
    padding: 0 1rem;

    @media ${MqTablet} {
        padding: 0;
        max-width: 44rem;
    }

    @media ${MqDesktop} {
        max-width: 69rem;
    }
`

export default Object.assign(MediaKit, {
    PaddedBox,
    Content,
    Icon,
})
