import React from 'react'
import styled from 'styled-components'
import { MqTablet, MqDesktop } from 'streamr-ui/css/consts'
import MediaKit from '../MediaKit'
import brandGuide from './assets/brand_guide.png'
import brandGuide2x from './assets/brand_guide@2x.png'
import Container from '~shared/Container'
import Br from '~shared/Br'
import { Link, PrimaryButton, ButtonSize } from 'streamr-ui'

const UnstyledBrandGuide = (props) => (
    <Container {...props}>
        <MediaKit.PaddedBox $appear>
            <MediaKit.Content>
                <h2>Brand Guide</h2>
                <p>
                    Add the public Community file to your
                    <Br tablet />
                    Figma and get
                    <Br desktop />
                    instant access to SVG
                    <Br tablet />
                    logos, token symbols and icons.
                </p>
                <PrimaryButton
                    tag={Link}
                    size={ButtonSize.Moose}
                    href="https://www.figma.com/community/file/1053327102137635196/Streamr-Brand-Assets"
                    blank
                >
                    Visit Brand Guide
                </PrimaryButton>
            </MediaKit.Content>
            <MediaKit.Icon>
                <img src={brandGuide} srcSet={brandGuide2x} alt="Visit Brand Guide" />
            </MediaKit.Icon>
        </MediaKit.PaddedBox>
    </Container>
)

const BrandGuide = styled(UnstyledBrandGuide)`
    padding: 0 1rem;

    ${MediaKit.Icon} {
        margin-left: 16px;

        img {
            width: 84px;
        }
    }

    @media ${MqTablet} {
        padding: 0;
        max-width: 44rem;

        ${MediaKit.Icon} {
            margin-left: 0;
            margin-right: 44px;
        }
    }

    @media ${MqDesktop} {
        max-width: 69rem;

        ${MediaKit.Icon} {
            margin-right: 16px;

            img {
                width: 120px;
            }
        }
    }
`

export default BrandGuide
