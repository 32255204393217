import React from 'react'
import styled from 'styled-components'
import { Regular, MqTablet, MqDesktop } from 'streamr-ui/css/consts'
import Illustration from './Illustration'
import useTranslation from '~utils/useTranslation'
import Container from '~shared/Container'

const Inner = styled.div`
    margin-top: 4.5rem;

    @media ${MqTablet} {
        margin-top: 6rem;
    }

    @media ${MqDesktop} {
        margin-top: 0.5rem;
        margin-left: 4rem;
        max-width: 30rem;
    }
`

const IllustrationWrapper = styled.div`
    margin: 0 4rem;
    width: 10rem;

    @media ${MqTablet} {
        width: 12.5rem;
    }

    @media ${MqDesktop} {
        width: 15rem;
    }
`

const Outer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        font-size: 2rem;
        font-weight: ${Regular};
        line-height: 2.5rem;
        margin-top: 0;
        margin-bottom: 0.5rem;
    }

    p {
        font-size: 1rem;
        line-height: 1.75rem;
        margin: 0;
    }

    @media ${MqTablet} {
        margin: 0 auto;
        max-width: 520px;

        h1 {
            font-size: 2.5rem;
            line-height: 3.25rem;
            margin-bottom: 1rem;
        }

        p {
            font-size: 1.125rem;
            line-height: 1.875rem;
        }
    }

    @media ${MqDesktop} {
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        max-width: none;

        h1 {
            font-size: 3rem;
            line-height: 3.5rem;
        }

        p {
            max-width: none;
        }
    }
`

const UnstyledHero = ({ className }) => {
    const { t } = useTranslation()

    return (
        <Container className={className}>
            <Outer>
                <IllustrationWrapper $appear>
                    <Illustration id="design" />
                </IllustrationWrapper>
                <Inner>
                    <h1 $appear>{t('design.hero.title')}</h1>
                    <p $appear>{t('design.hero.description')}</p>
                </Inner>
            </Outer>
        </Container>
    )
}

const Hero = styled(UnstyledHero)``

export default Hero
