import React from 'react'
import styled, { css } from 'styled-components'
import { withAssetPrefix } from 'gatsby'
import logoHorizontalPositiveMonoLockup from './assets/logo_horizontal_positive_mono_lockup.svg'
import logoHorizontalColourLockup from './assets/logo_horizontal_colour_lockup.svg'
import logoHorizontalReversedMonoLockup from './assets/logo_horizontal_reversed_mono_lockup.svg'
import logoMarkVerticalPositiveMonoLockup from './assets/logo_vertical_positive_mono_lockup.svg'
import logoVerticalColourLockup from './assets/logo_vertical_colour_lockup.svg'
import logoVerticalReversedMonoLockup from './assets/logo_vertical_reversed_mono_lockup.svg'
import logoPositiveMonoMark from './assets/logo_positive_mono_mark.svg'
import logoColourMark from './assets/logo_colour_mark.svg'
import logoReversedMonoMark from './assets/logo_reversed_mono_mark.svg'
import useTranslation from '~utils/useTranslation'
import { ButtonLook, forgeButton, Link } from 'streamr-ui'

const assets = {
    'horizontal-positive-mono-lockup': logoHorizontalPositiveMonoLockup,
    'horizontal-colour-lockup': logoHorizontalColourLockup,
    'horizontal-reversed-mono-lockup': logoHorizontalReversedMonoLockup,
    'vertical-positive-mono-lockup': logoMarkVerticalPositiveMonoLockup,
    'vertical-colour-lockup': logoVerticalColourLockup,
    'vertical-reversed-mono-lockup': logoVerticalReversedMonoLockup,
    'positive-mono-mark': logoPositiveMonoMark,
    'colour-mark': logoColourMark,
    'reversed-mono-mark': logoReversedMonoMark,
}

const DownIcon = () => (
    <svg width="12" height="12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6 3v6M6 9L3.75 6.75M6 9l2.25-2.25"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

const DownloadButton = forgeButton('DownloadButton', [
    ButtonLook.Zero,
    ButtonLook.Regular,
    css`
        --bg: #a3a3a3;
        --hoverBg: #b7b7b7;
        --activebg: #cdcdcd;
        --text: #ffffff;
        --fontSize: 10px;
        --height: 20px;
        --lineHeight: 20px;
        --padding: 1px 4px 0 8px;
        --opacity: 0;
        bottom: 12px;
        display: flex;
        letter-spacing: 0.05em;
        position: absolute;
        right: 12px;
        text-transform: uppercase;
        transition: 300ms background-color ease-in-out, 180ms opacity ease-in-out;

        svg {
            display: block;
        }
    `,
])

const UnstyledLogo = ({ className, id }) => {
    const { t } = useTranslation()
    const caption = t(`design.logoAssets.logo.${id}`)

    return (
        <div className={className}>
            <img src={assets[id]} alt={caption} />
            <DownloadButton tag={Link} href={withAssetPrefix(`/resources/logos/${id}.zip`)}>
                <span>Download</span>
                <DownIcon />
            </DownloadButton>
        </div>
    )
}

const Logo = styled(UnstyledLogo)`
    background-color: #EFEFEF;
    border-radius: 8px;

    img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    :hover,
    :focus-within {
        ${DownloadButton} {
            opacity: 1;
        }
    }
}
`

export default Logo
