import React from 'react'
import styled from 'styled-components'
import { Regular } from 'streamr-ui/css/consts'
import Hero from './Hero'
import Logos from './Logos'
import MediaKit from './MediaKit'
import BrandGuide from './BrandGuide'
import PrestyledLayout from '~shared/Layout'
import Display from '~shared/Display'
import Spacer from '~shared/Spacer'

const Layout = styled(PrestyledLayout)`
    ${PrestyledLayout.Inner} h1,
    ${PrestyledLayout.Inner} h2,
    ${PrestyledLayout.Inner} h3 {
        font-weight: ${Regular};
    }
`

const UnstyledDesign = ({ className }) => (
    <Layout className={className} $backgroundColor="white">
        <Spacer head="3.5rem,5.5rem,17rem">
            <Hero />
        </Spacer>
        <Display as={Spacer} head=",,17rem" mobile="none" desktop="block">
            <Logos />
        </Display>
        <Spacer head="6.5rem,7.5rem," tail="3.5rem,6rem,13.5rem">
            <MediaKit />
            <Display as={Spacer} mobile="none" tablet="block" head=",2.5rem,3.5rem">
                <BrandGuide />
            </Display>
        </Spacer>
    </Layout>
)

const Design = styled(UnstyledDesign)`
    color: #0c009a;
`

export default Design
