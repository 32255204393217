import React from 'react'
import styled from 'styled-components'
import design from './assets/design.png'
import Image from '~shared/Image'

const Outer = styled.div`
    left: 0;
    position: absolute;
    top: -16%;
    width: 100%;
`

const Inner = styled.div`
    left: -18%;
    position: relative;
    width: 136%;
`

const UnstyledIllustration = ({ className }) => (
    <div className={className}>
        <Outer>
            <Inner>
                <Image src={design} alt="" />
            </Inner>
        </Outer>
    </div>
)

const Illustration = styled(UnstyledIllustration)`
    height: 0;
    padding-top: 103%;
    position: relative;

    img {
        width: 100%;
    }

    svg {
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
    }
`

export default Illustration
