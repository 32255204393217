import React from 'react'
import styled from 'styled-components'
import Logo from './Logo'
import Container from '~shared/Container'

const Heading = styled.h1`
    font-size: 2.5rem;
    margin: 0;
    margin-bottom: 1.5rem;
`

const LogoGrid = styled.div`
    display: grid;
    grid-column-gap: 2.5rem;
    grid-row-gap: 2.5rem;
    grid-template-columns: 1fr 1fr 1fr;
`

const LogoItem = styled.div`
    height: 0;
    padding-bottom: 100%;
    position: relative;

    ${Logo} {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`

const UnstyledLogos = ({ className }) => (
    <div className={className}>
        <Container>
            <Heading $appear>Logo Assets</Heading>
            <p $appear>All downloads contain SVG and PNG formats.</p>
            <LogoGrid>
                <LogoItem $appear>
                    <Logo id="colour-mark" />
                </LogoItem>
                <LogoItem $appear>
                    <Logo id="reversed-mono-mark" />
                </LogoItem>
                <LogoItem $appear>
                    <Logo id="positive-mono-mark" />
                </LogoItem>
                <LogoItem $appear>
                    <Logo id="vertical-colour-lockup" />
                </LogoItem>
                <LogoItem $appear>
                    <Logo id="vertical-reversed-mono-lockup" />
                </LogoItem>
                <LogoItem $appear>
                    <Logo id="vertical-positive-mono-lockup" />
                </LogoItem>
                <LogoItem $appear>
                    <Logo id="horizontal-colour-lockup" />
                </LogoItem>
                <LogoItem $appear>
                    <Logo id="horizontal-reversed-mono-lockup" />
                </LogoItem>
                <LogoItem $appear>
                    <Logo id="horizontal-positive-mono-lockup" />
                </LogoItem>
            </LogoGrid>
        </Container>
    </div>
)

const Logos = styled(UnstyledLogos)`
    p {
        margin: 0;
    }

    ${LogoGrid} {
        margin-top: 3.5rem;
    }
`

export default Logos
