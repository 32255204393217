import styled, { css } from 'styled-components'

const Image = styled.img`
    display: block;
    margin: 0 auto;

    ${({ responsive }) =>
        !!responsive &&
        css`
            max-width: 100%;
        `}
`

export default Image
