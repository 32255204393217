import React from 'react'
import Design from '../components/Design'
import page from '~utils/page'
import { RESOURCES } from '~shared/Nav'
import Head from '~shared/Head'

// eslint-disable-next-line import/no-unused-modules
export default page(Design, {
    highlight: RESOURCES,
    headComponent: (
        <Head
            mediaDescription="SVG and PNG logos"
            // eslint-disable-next-line max-len
            description="The home of Streamr design assets, including logos, imagery, colours and fonts. Everything is provided under a Creative Commons sharealike license."
        />
    ),
})
